@use "./config/" as *;
@forward "./main.scss";
@forward "./compareplayer/compareplayer";
.load-bg-pattern {
    .waf-compareplayer {
        &::before {
            @include bgImg("cssimages/eventlisting-bg.png", center, cover, no-repeat);
        }
    }
}
@include mq(col-tablet) {
    .waf-compareplayer {
        .tab {
            &-container {
                margin-top: var(--space-20);
            }
            &-container-wrapper {
                position: relative;
                &::before {
                    content: '';
                    height: calc(100% + 8rem);
                    pointer-events: none;
                    border-radius: 2.4rem;
                    background: hsl(var(--hsl-neutral-0)/0.3);
                    z-index: var(--z-pattern);
                    width: var(--lg-container-max-width);
                    @include position(-4rem, 0, 0, -1.5rem);
                }
            }
            &-head-wrapper {
                padding-inline: var(--space-6);
                &::before {
                    content: unset;
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-compareplayer {
        .tab {
            &s {
                margin: 0 0 11.6rem auto;
            }
            &-container-wrapper {
                &::before {
                    left: -3.2rem;
                }
            }
        }
    }
    .load-bg-pattern {
        .waf-compareplayer {
            &::before {
                @include bgImg("cssimages/pattern/head-to-head-bg-web.png");
            }
        }
    }
}
@include mq(col-xl) {
    .waf-compareplayer {
        .tab-container-wrapper::before {
            left: -4rem;
        }
    }
}